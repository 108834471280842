import { CSSProperties } from 'react';
import { FadeLoader } from 'react-spinners';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red'
};

function Loader({ loading = true }: { loading?: boolean }) {
  return (
    <div
      style={{ height: 'calc(-5rem + 100vh)' }}
      className='sweet-loading flex w-full items-center justify-center'
    >
      <FadeLoader
        color={'#36d7b7'}
        loading={loading}
        aria-label='Loading Spinner'
        data-testid='loader'
      />
    </div>
  );
}

export default Loader;
