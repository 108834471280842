import api from './api';
import {
  AddEstimatePayloadType,
  GetInventoryItemTxnsResponse,
  GetInventorydetailsApiResponse,
  GetItemInventoryApiResponse,
  UpdateEstimatePayloadType,
  removeQuantityPayloadType,
  updateItemInventoryApiResponse
} from './apiTypes';
import { downloadFile } from './itemsPricing';

export const inventoryApi = api.injectEndpoints({
  endpoints: builder => ({
    getInventoryDetails: builder.query<GetInventorydetailsApiResponse, any>({
      query: ({ search }: any = {}) => {
        let url = 'api/web/v1/inventory/getinventorydetailsv1';
        if (search) {
          url += `?searchtext=${search}`;
        }
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['GetInventoryDetailsTypes']
    }),

    getInventoryItemTxns: builder.query<
      GetInventoryItemTxnsResponse,
      { itemIDs: number[] }
    >({
      query: ({ itemIDs }: { itemIDs: number[] }) => ({
        url: `api/web/v1/inventory/getitemsinventory-txns?itemids=${itemIDs}`,
        method: 'GET'
      }),
      providesTags: ['GetItemInventoryTxn'],
      keepUnusedDataFor: 0
    }),
    createEstimate: builder.mutation({
      query: (payload: AddEstimatePayloadType) => ({
        url: '/api/web/v1/inventory/addestimate',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['GetInventoryDetailsTypes', 'GetItemInventoryTxn']
    }),
    updateEstimate: builder.mutation({
      query: ({
        payload,
        itemID
      }: {
        payload: UpdateEstimatePayloadType[];
        itemID: number;
      }) => ({
        url: `/api/web/v1/inventory/updateitemestimateinventory?itemid=${itemID}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['GetInventoryDetailsTypes', 'GetItemInventoryTxn']
    }),
    removeQuantity: builder.mutation({
      query: (payload: removeQuantityPayloadType) => ({
        url: '/api/web/v1/inventory/removequantity',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['GetInventoryDetailsTypes', 'GetItemInventoryTxn']
    }),
    getInventoryItem: builder.query<
      GetItemInventoryApiResponse,
      { itemID: number }
    >({
      query: ({ itemID }: { itemID: number }) => ({
        url: `api/web/v1/inventory/getiteminventory?itemid=${itemID}`,
        method: 'GET'
      })
    }),
    getupdateInventoryItem: builder.query<
      updateItemInventoryApiResponse,
      { itemID: number }
    >({
      query: ({ itemID }: { itemID: number }) => ({
        url: `api/web/v1/inventory/getiteminventorypendingestimates?itemid=${itemID}`,
        method: 'GET'
      }),
      transformResponse: (response: updateItemInventoryApiResponse) => {
        const transformedEstimates = response.data.itemEstimates.map(
          estimate => ({
            ...estimate,
            actualQuantity: '',
            updateQuantity: 0,
            notes: ''
          })
        );

        return {
          ...response,
          data: {
            ...response.data,
            itemEstimates: transformedEstimates
          }
        };
      }
    }),
    downloadInventoryxls: builder.query<any, void | object>({
      query: () => ({
        url: '/api/web/v1/inventory/download/iteminventoryxls',
        method: 'GET',
        responseHandler: res => downloadFile(res, 'itemInventoryDetails.xlsx'),
        cache: 'no-cache'
      })
    }),
    bulkUploadInventory: builder.mutation({
      query: (payload: any) => ({
        url: '/api/web/v1/inventory/bulkuploaditeminventory',
        method: 'POST',
        responseHandler: res =>
          downloadFile(res, 'itemInventoryStatusDetails.xlsx'),
        body: payload,
        formData: true,
        cache: 'no-cache'
      }),
      invalidatesTags: ['GetInventoryDetailsTypes']
    }),
    updateMaxOrderQuantity: builder.mutation({
      query: ({
        itemid,
        maxorderquantity
      }: {
        itemid: number;
        maxorderquantity: number;
      }) => ({
        url: '/api/web/v1/inventory/updatemaxorderquantity',
        method: 'PUT',
        params: { itemid, maxorderquantity }
      }),
      invalidatesTags: ['GetInventoryDetailsTypes']
    })
  })
});

export const {
  useGetInventoryDetailsQuery,
  useLazyGetInventoryDetailsQuery,
  useCreateEstimateMutation,
  useGetInventoryItemQuery,
  useLazyGetInventoryItemQuery,
  useLazyGetupdateInventoryItemQuery,
  useGetupdateInventoryItemQuery,
  useRemoveQuantityMutation,
  useUpdateEstimateMutation,
  useGetInventoryItemTxnsQuery,
  useLazyGetInventoryItemTxnsQuery,
  useLazyDownloadInventoryxlsQuery,
  useBulkUploadInventoryMutation,
  useUpdateMaxOrderQuantityMutation
} = inventoryApi;
