import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '../assets/images/ft-logo-black.svg';
import Button from '../components/Common/Button';
import { setIsLoggedIn } from '../redux/Slices/AuthSlice';
import { useAppSelector } from '../redux/store/hooks';
import { useVerifyOtpMutation } from '../services/authApi';
import { setItem } from '../utils/storage';

let currentIndex = 0;
function Otp() {
  const [showError, setShowError] = useState<boolean>(false);
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(''));
  const isOtpIncomplete = otp.some(digit => digit === '');
  const [verifyOtp, { isLoading, data, isError }] = useVerifyOtpMutation();
  const { mobile } = useAppSelector(state => state.root.auth);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    const isNumeric = /^\d*$/.test(value);
    if (!isNumeric) {
      return;
    }
    const newOtp: string[] = [...otp];
    newOtp[currentIndex] = value.substring(value.length - 1);
    setOtp(newOtp);
    if (!value) {
      setActiveOTPIndex(currentIndex - 1);
    } else {
      setActiveOTPIndex(currentIndex + 1);
    }
    setShowError(false);
  };

  const keyDownHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    currentIndex = index;
    if (event.key === 'Backspace') {
      const newOtp: string[] = [...otp];
      if (newOtp[currentIndex] !== '') {
        newOtp[currentIndex] = '';
        setOtp(newOtp);
        setActiveOTPIndex(currentIndex);
      } else {
        newOtp[currentIndex] = '';
        setOtp(newOtp);
        setActiveOTPIndex(currentIndex - 1);
      }
    }
  };

  useEffect(() => {
    // Timer to decrement time every second
    const timer = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timer);
        } else {
          setMinutes(prevMinutes => prevMinutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [minutes, seconds]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  const handleVerifyOtp = async () => {
    try {
      const body = {
        mobile,
        otp: otp.join('')
      };
      const res = await verifyOtp(body).unwrap();
      if (res.authToken) {
        setItem({ key: 'token', value: res.authToken });
        dispatch(setIsLoggedIn(true));
        const decoded = jwtDecode(res.authToken);
        navigate('/orders/order-list');
      }
    } catch (error: any) {
      if (error?.data?.data) {
        toast.error(error?.data?.error);
      } else {
        toast.error('Unknown error');
      }
    }
  };

  const onKeyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      handleVerifyOtp();
    }
  };

  const errorMessage = (error: any) => {
    if (showError && otp.join('') !== '') {
      if (error) {
        toast.error(error?.data?.error);
        return error?.data?.error;
      } else {
        return 'Unknown error';
      }
    }
  };

  return (
    <div className='relative flex h-screen w-full flex-col items-start justify-center bg-[#FFFFFF]'>
      <img
        className='absolute top-0 flex w-full justify-center'
        src={'TopGradient'}
        alt=''
      />

      <div className='z-50 flex h-full w-full flex-col items-center justify-center'>
        <div className='mt-8 flex flex-row justify-center gap-8'>
          <div className='flex flex-col items-center'>
            <div className='flex flex-col items-center'>
              <img src={logo} alt='ft' className='mb-2' />
              <p className='font-avenir mr-1 text-center text-sm font-medium text-[#3C424C]'>
                Enter OTP shared on mobile{' '}
                <span className='spanclasses'>{mobile}</span>
              </p>
              <div>
                <button
                  onClick={() => {
                    navigate('/login');
                  }}
                ></button>
              </div>
            </div>
            <div className='flex items-center justify-center '>
              {otp.map((_, index) => (
                <>
                  <input
                    ref={index === activeOTPIndex ? inputRef : null}
                    type='tel'
                    className='m-2 mb-6 h-8 w-8 rounded-md border border-[#E3E5E9] bg-transparent text-center text-xl font-semibold outline-none'
                    onChange={event => {
                      onChangeHandler(event);
                    }}
                    onKeyDown={event => {
                      keyDownHandler(event, index);
                      onKeyDownHandler(event);
                    }}
                    value={otp[index]}
                  />
                </>
              ))}
            </div>
            {isError ? (
              <h4 className='text-secondaryColor '>{errorMsg}</h4>
            ) : (
              data?.message && <p className='text-[green]'>{data.message}</p>
            )}
            <Button
              disabled={isOtpIncomplete || data?.message}
              isLoading={isLoading}
              label='Verify OTP'
              handleClickBtn={handleVerifyOtp}
              variant='contained'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Otp;
