export const newCategoryDetailsData = {
  caption: '',
  description: '',
  images: [],
  listingOrder: 0,
  name: '',
  parentID: null,
  id: null
};

export const newSubCategoryDetailsData = {
  caption: '',
  description: '',
  images: [],
  listingOrder: 0,
  name: '',
  parentID: null,
  id: null
};
