import api from './api';

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    sendOtp: builder.mutation({
      query: payload => ({
        url: '/api/web/auth/sendmockotp',
        method: 'POST',
        body: payload
      })
    }),
    verifyOtp: builder.mutation({
      query: payload => ({
        url: '/api/web/auth/verifyotp',
        method: 'POST',
        body: payload
      })
    }),
    isEmailMobileAvailable: builder.query({
      query: ({ email, mobile }: { email?: string; mobile?: string } = {}) => {
        let url = '/api/web/auth/isemailmobileavailable';

        const params = new URLSearchParams();

        if (email) {
          params.append('email', email);
        }

        if (mobile) {
          params.append('mobile', mobile);
        }

        if (params.toString()) {
          url += `?${params.toString()}`;
        }

        return {
          url,
          method: 'GET'
        };
      },

      providesTags: ['GetCategoryDashboardDetails']
    })
  })
});

export const {
  useSendOtpMutation,
  useVerifyOtpMutation,
  useLazyIsEmailMobileAvailableQuery
} = authApi;
