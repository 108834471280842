import { createSlice } from '@reduxjs/toolkit';

import { agentsApi } from '../../../services/agentsApi';

import { InitalStateType } from './AgentsSliceTypes';

const initalState: InitalStateType = {
  IsAddPickerAgentButtonClicked: false,
  pickerAgentFormData: {
    name: '',
    email: '',
    mobile: '',
    pickerAddress: {
      address1: '',
      city: '',
      pincode: '',
      state: '',
      address2: ''
    }
  },
  allAgentsData: [],
  selectedRow: null
};

const agentsSlice = createSlice({
  name: 'agents',
  initialState: initalState,
  reducers: {
    setIsAddPickerAgentButtonClicked: (state, action) => {
      state.IsAddPickerAgentButtonClicked = action.payload;
    },
    setPickerAgentFormData: (state, action) => {
      const { key, value } = action.payload;
      return {
        ...state,
        pickerAgentFormData: {
          ...state.pickerAgentFormData,
          [key]: value
        }
      };
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    setAllAgentsData: (state, action) => {
      state.allAgentsData = action.payload;
    },
    resetAgentsState: state => {
      state.pickerAgentFormData = {
        name: '',
        email: '',
        mobile: '',
        pickerAddress: {
          address1: '',
          address2: '',
          city: '',
          pincode: '',
          state: ''
        }
      };
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      agentsApi.endpoints.getAllPickerAgents.matchFulfilled,
      (state, action) => {
        state.allAgentsData = action.payload.data;
      }
    );
  }
});

export const {
  setIsAddPickerAgentButtonClicked,
  setPickerAgentFormData,
  resetAgentsState,
  setSelectedRow,
  setAllAgentsData
} = agentsSlice.actions;

export default agentsSlice.reducer;
