import api from './api';

export const agentsApi = api.injectEndpoints({
  endpoints: builder => ({
    addAgent: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/picker/create',
        method: 'Post',
        body: payload
      }),
      invalidatesTags: ['GetAllAgentPickers']
    }),
    getAllPickerAgents: builder.query({
      query: () => ({
        url: '/api/web/v1/picker/all',
        method: 'Get'
      }),
      providesTags: ['GetAllAgentPickers']
    }),
    assignAgentPicker: builder.mutation({
      query: ({ pickerId, payload }) => ({
        url: '/api/web/v1/picker/assignpicker',
        method: 'POST',
        body: payload,
        params: { pickerId }
      })
    }),
    reAssignAgentPicker: builder.mutation({
      query: ({ newPickerId, oldPickerId, orderId }) => ({
        url: '/api/web/v1/picker/reassignpicker',
        method: 'PUT',
        params: { newPickerId, oldPickerId, orderId }
      }),
      invalidatesTags: ['GetAllAgentPickers']
    }),
    changePickerStatus: builder.mutation({
      query: ({ makePickerActive, pickerIds }) => ({
        url: '/api/web/v1/picker/changestatus',
        method: 'PUT',
        body: [pickerIds],
        params: { makePickerActive }
      }),
      invalidatesTags: ['GetAllAgentPickers']
    }),
    flexiSearchPicker: builder.query({
      query: flexisearch => ({
        url: '/api/web/v1/picker/flexisearchpicker',
        method: 'Get',
        params: { flexisearch }
      })
    })
  })
});

export const {
  useAddAgentMutation,
  useGetAllPickerAgentsQuery,
  useAssignAgentPickerMutation,
  useReAssignAgentPickerMutation,
  useChangePickerStatusMutation,
  useFlexiSearchPickerQuery,
  useLazyFlexiSearchPickerQuery
} = agentsApi;
