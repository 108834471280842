import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Loader from './components/Common/Loader';
import Routing from './components/Routing';
import useWarnOnRefresh from './hooks/useWarnOnRefresh';
import ErrorBoundary from './utils/ErrorBoundary';

function App() {
  useWarnOnRefresh();
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Routing />
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
