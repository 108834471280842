import api from './api';

export const downloadFile = async (response: Response, name: string) => {
  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const itemsPricingApi = api.injectEndpoints({
  endpoints: builder => ({
    bulkUploadPricing: builder.mutation({
      query: (payload: any) => ({
        url: '/api/web/v1/item/pricing/bulkuploaditemprices',
        method: 'POST',
        responseHandler: res => downloadFile(res, 'itemPriceDetails.xlsx'),
        body: payload,
        formData: true,
        cache: 'no-cache'
      }),
      invalidatesTags: ['GetItemDetailsByGroup']
    }),
    downloadxls: builder.query<any, void | object>({
      query: () => ({
        url: '/api/web/v1/item/pricing/download/itempricesxls',
        method: 'GET',
        responseHandler: res => downloadFile(res, 'itemPriceDetails.xlsx'),
        cache: 'no-cache'
      })
    }),
    downloadcsv: builder.query({
      query: () => ({
        url: '/api/web/v1/item/pricing/download/itempricescsv',
        method: 'GET'
      })
    })
  })
});

export const {
  useBulkUploadPricingMutation,
  useLazyDownloadxlsQuery,
  useLazyDownloadcsvQuery
} = itemsPricingApi;
