import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authApi } from '../../../services/authApi';

type AuthState = {
  mobile: string;
  emailError: string;
  mobileNumberError: string;
  isLoggedIn: boolean;
  isEmailAndMobileAvailable: boolean;
};

const initialState: AuthState = {
  mobile: '',
  emailError: '',
  mobileNumberError: '',
  isLoggedIn: false,
  isEmailAndMobileAvailable: false
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setEmailError: (state, action) => {
      state.emailError = action.payload;
    },
    setMobileNumberError: (state, action) => {
      state.mobileNumberError = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setIsEmailMobileAvailable: (state, action) => {
      state.isEmailAndMobileAvailable = action.payload;
    },
    resetAuthState: () => initialState
  },

  extraReducers: builder => {
    builder.addMatcher(
      authApi.endpoints.isEmailMobileAvailable.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.isEmailAndMobileAvailable = action.payload.data;
      }
    );
    builder.addMatcher(
      authApi.endpoints.isEmailMobileAvailable.matchRejected,
      (state, action: PayloadAction<any>) => {
        // toast.error('Phone Number or Email Already Exists');
        state.isEmailAndMobileAvailable =
          action.payload.data.isemailmobileexists;
      }
    );
  }
});

export const {
  setMobile,
  setEmailError,
  setMobileNumberError,
  setIsLoggedIn,
  resetAuthState,
  setIsEmailMobileAvailable
} = authSlice.actions;

export default authSlice.reducer;
