import { createSlice } from '@reduxjs/toolkit';

import { categoriesApi } from '../../../services/categories/categoriesApi';
import { categoriesEditApi } from '../../../services/categories/categoriesEditApi';

import { newCategoryDetailsData } from './categoriesInitialState';
import { CategoryInitalStateType } from './categoryTypes';

const initalState: CategoryInitalStateType = {
  categoryDashboardDetails: { data: [], message: '', status: '' },
  activeTabId: '',
  categoryDetails: {},
  subCategoryDetails: [],
  subCategories: [],
  isSubCategoryNameAvailable: false,
  itemsMappedToCategoryAndSubCategory: { data: [], message: '', status: '' },
  subCategoryDashboard: { data: [], message: '', status: '' },
  selectedSubCategory: {},
  isSubCatClicked: false
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initalState,
  reducers: {
    setCategoryDetails: (state, action) => {
      state.categoryDetails = action.payload;
    },
    setSubCategoryDetails: (state, action) => {
      state.subCategoryDetails = action.payload;
    },
    addSubCategory: state => {
      state.subCategoryDetails.push(newCategoryDetailsData);
    },
    setSubCategories: (state, action) => {
      state.subCategories = action.payload;
    },
    setIsSubCategoryNameAvailable: (state, action) => {
      state.isSubCategoryNameAvailable = action.payload;
    },
    setSelectedSubCategory: (state, action) => {
      state.selectedSubCategory = action.payload;
    },
    setActiveTabId: (state, action) => {
      state.activeTabId = action.payload;
    },
    setIsSubCatClicked: (state, action) => {
      state.isSubCatClicked = action.payload;
    },
    resetCategoryState: state => {
      // initalState;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      categoriesApi.endpoints.getCategoryDashboardDetails.matchFulfilled,
      (state, action) => {
        state.categoryDashboardDetails.data = action.payload.data;
      }
    );
    builder.addMatcher(
      categoriesApi.endpoints.getItemsMappedToCategoryAndSubCategory
        .matchFulfilled,
      (state, action) => {
        state.itemsMappedToCategoryAndSubCategory.data = action.payload.data;
      }
    );
    builder.addMatcher(
      categoriesApi.endpoints.getSubCategoriesByCategoryId.matchFulfilled,
      (state, action) => {
        state.subCategories = action.payload.data;
      }
    );
    builder.addMatcher(
      categoriesEditApi.endpoints.editCategoryBasicDetails.matchFulfilled,
      (state, action) => {
        state.categoryDetails = action.payload.data;
      }
    );
    builder.addMatcher(
      categoriesEditApi.endpoints.getSubCategoryDashboard.matchFulfilled,
      (state, action) => {
        state.subCategoryDashboard.data = action.payload.data;
      }
    );
  }
});

export const {
  setCategoryDetails,
  setSubCategoryDetails,
  addSubCategory,
  setSubCategories,
  setIsSubCategoryNameAvailable,
  resetCategoryState,
  setActiveTabId,
  setSelectedSubCategory,
  setIsSubCatClicked
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
