import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from '@headlessui/react';
import { AnchorProps } from '@headlessui/react/dist/internal/floating';
import { ReactNode, useEffect, useRef } from 'react';

const CommonPopover = ({
  label,
  children,
  isPopoverOpen,
  position,
  classes,
  panelClasses,
  overidePanelClass,
  setIsPopoverOpen
}: {
  label?: string | ReactNode;
  children: ReactNode;
  isPopoverOpen?: boolean;
  position?: AnchorProps;
  classes?: string;
  panelClasses?: string;
  overidePanelClass?: boolean;
  setIsPopoverOpen?: (open: boolean) => void;
}) => {
  const panelRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setIsPopoverOpen && setIsPopoverOpen(false);
      }
    };

    if (isPopoverOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopoverOpen, setIsPopoverOpen]);

  return (
    <Popover className={classes}>
      <PopoverButton className='focus:outline-none data-[active]:text-black data-[hover]:text-black data-[focus]:outline-1 data-[focus]:outline-white'>
        {label}
      </PopoverButton>
      <Transition
        show={isPopoverOpen}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <PopoverPanel
          ref={panelRef}
          anchor={position}
          className={
            overidePanelClass
              ? panelClasses
              : 'divide-y divide-white/5 bg-white bg-white/5 text-sm/6 [--anchor-gap:var(--spacing-5)]'
          }
        >
          {children}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default CommonPopover;
