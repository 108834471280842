import { createSlice } from '@reduxjs/toolkit';

import { categoriesApi } from '../../../services/categories/categoriesApi';
import { itemsApi } from '../../../services/itemsApi';

import {
  isButtonsClickedData,
  newItemGroupsData,
  newItemsData,
  newItemsDataApiRes
} from './itemsSliceInitialState';
import { InitalStateType, IsButtonsClicked } from './types';

const initalState: InitalStateType = {
  isTemAvailable: false,
  itemDashboardDetails: {
    data: [],
    message: '',
    status: ''
  },
  itemDetailsByPacks: { data: [], message: '', status: '' },
  itemGroupDashboardDetails: { data: [], message: '', status: '' },
  addedListItemsToCategory: [],
  addedListItemsToSubCategory: {},
  searchValue: '',
  newItemsData,
  newItemsDataApiRes,
  packSizes: [],
  packSizesData: [],
  isButtonsClicked: isButtonsClickedData,
  itemGroups: newItemGroupsData,
  addedListItemsToItemGroup: [],
  activeTabId: '',
  activeStepper: '1',
  selectedRows: [],
  isEditMode: false,
  selectedItemGroupId: 0,
  itemGroupName: '',
  checkedItemDetails: [],
  selectedItemIds: [],
  selectedRow: {},
  itemGroupItems: {
    data: {
      itemGroupID: 0,
      itemGroupName: '',
      listingOrder: 0,
      itemPacks: []
    },
    message: '',
    status: ''
  },
  isSkuidAvailable: false
};

const itemsSlice = createSlice({
  name: 'items',
  initialState: initalState,
  reducers: {
    setNewItemsData: (state, action) => {
      state.newItemsData = { ...action.payload };
    },
    addNewItem: (state, action) => {
      const newItem = action.payload;
      if (
        !state.newItemsData.itemGeneralDetails?.some(
          item => item.itemTypeCode === newItem.itemTypeCode
        )
      ) {
        state.newItemsData.itemGeneralDetails?.push(newItem);
      }
    },
    setCheckedItemDetails: (state, action) => {
      state.checkedItemDetails = action.payload;
    },
    setIsSkuidAvailable: (state, action) => {
      state.isSkuidAvailable = action.payload;
    },
    addPackoldSizes: (state, action) => {
      const { itemid, itemTypeCode, packSize } = action.payload;
      packSize.itemTypeCode = itemTypeCode;
      state.packSizes.push(packSize);
    },
    addPackSizes: (state, action) => {
      const { itemId, itemTypeCode, packSize } = action.payload;
      const index = state.packSizes.findIndex(
        el => el.itemTypeCode === itemTypeCode
      );
      if (index !== -1) {
        state.packSizes[index].packSizesArray.push(packSize);
      } else {
        state.packSizes.push({
          itemId,
          itemTypeCode,
          packSizesArray: [packSize]
        });
      }
    },
    updatePackSizes: (state, action) => {
      const { key, value, itemTypeCode, packSizeIndex } = action.payload;
      const index = state.packSizes.findIndex(
        el => el.itemTypeCode === itemTypeCode
      );
      state.packSizes[index].packSizesArray[packSizeIndex][key] = value;
    },
    removePackSize: (state, action) => {
      const { packSizeIndex, itemTypeCode } = action.payload;
      const index = state.packSizes.findIndex(
        el => el.itemTypeCode === itemTypeCode
      );
      state.packSizes[index].packSizesArray.splice(packSizeIndex, 1);
    },
    setIsAddButtonsClicked: (state, action) => {
      const { key, value } = action.payload;
      state.isButtonsClicked[key as keyof IsButtonsClicked] = value;
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setSelectedItemGroupId: (state, action) => {
      state.selectedItemGroupId = action.payload;
    },
    updateItemGeneralDetails: (state, action) => {
      const { id, key, value } = action.payload;
      const itemIndex: any = state.newItemsData?.itemGeneralDetails?.findIndex(
        item => item.itemTypeCode === id
      );
      if (itemIndex !== -1 && key && key !== 'itemGeneralDetails') {
        if (
          state.newItemsData?.itemGeneralDetails &&
          state.newItemsData.itemGeneralDetails[itemIndex]
        ) {
          if (key === 'images') {
            const previousImages =
              state.newItemsData.itemGeneralDetails[itemIndex].images || [];
            state.newItemsData.itemGeneralDetails[itemIndex].images = [
              ...previousImages,
              ...value
            ];
          } else {
            state.newItemsData.itemGeneralDetails[itemIndex][key] = value;
          }
        }
      } else {
        state.newItemsData.itemGeneralDetails = value;
      }
    },

    removeImages: (state, action) => {
      const { id, key, value } = action.payload;
      const itemIndex: any = state.newItemsData?.itemGeneralDetails?.findIndex(
        item => item.itemTypeCode === id
      );
      if (
        state.newItemsData?.itemGeneralDetails &&
        state.newItemsData.itemGeneralDetails[itemIndex]
      ) {
        state.newItemsData.itemGeneralDetails[itemIndex][key] = value;
      }
    },

    updateItemGeneralDetailsOnRequested: (state, action) => {
      state.newItemsData.itemGeneralDetails = action.payload;
    },
    setItemGroups: (state, action) => {
      const { key, value } = action.payload;
      state.itemGroups = {
        ...state.itemGroups,
        [key]: value
      };
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setAddListItemsToItemGroup: (state, action) => {
      state.addedListItemsToItemGroup = action.payload;
    },
    setAddListItemsToSubCategory: (state, action) => {
      state.addedListItemsToSubCategory = action.payload;
    },
    setSelectedItemIds: (state, action) => {
      state.selectedItemIds = action.payload;
    },
    setAvtiveTabId: (state, action) => {
      state.activeTabId = action.payload;
    },
    setActiveStepper: (state, action) => {
      state.activeStepper = action.payload;
    },
    setItemDashboardDetails: (state, action) => {
      state.itemDashboardDetails.data = action.payload;
    },
    setItemPacks: (state, action) => {
      state.itemGroupItems.data.itemPacks = action.payload;
    },
    resetState: state => {
      state.packSizes = [];
      state.searchValue = '';
      state.itemGroups = newItemGroupsData;
      state.newItemsData = newItemsData;
      state.newItemsDataApiRes = newItemsData;
      state.isButtonsClicked = isButtonsClickedData;
      state.selectedRows = [];
      state.addedListItemsToItemGroup = [];
      // state.selectedItemGroupId = 0;
      state.isEditMode = false;
      state.activeStepper = '1';
      state.isSkuidAvailable = false;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      itemsApi.endpoints.getItemDetailsByGroup.matchFulfilled,
      (state, action) => {
        state.itemDashboardDetails = action.payload;
      }
    );
    builder.addMatcher(
      itemsApi.endpoints.getItemDetailsByPacks.matchFulfilled,
      (state, action) => {
        state.itemDetailsByPacks = action.payload;
      }
    );
    builder.addMatcher(
      itemsApi.endpoints.isTemAvailable.matchFulfilled,
      (state, action) => {
        state.isTemAvailable = action.payload.data;
      }
    );
    builder.addMatcher(
      itemsApi.endpoints.getItemGroupDashboardDetails.matchFulfilled,
      (state, action) => {
        state.itemGroupDashboardDetails.data = action.payload.data;
      }
    );
    builder.addMatcher(
      itemsApi.endpoints.getItemGroupsDashboardDetails.matchFulfilled,
      (state, action) => {
        state.itemGroupDashboardDetails.data = action.payload.data;
      }
    );
    builder.addMatcher(
      itemsApi.endpoints.getItemgroupById.matchFulfilled,
      (state, action) => {
        state.itemGroupItems.data = action.payload?.data;
        if (action.payload.data?.itemPacks) {
          state.addedListItemsToItemGroup = action.payload.data?.itemPacks;
          state.itemGroups.name = action?.payload?.data?.itemGroupName;
          state.itemGroupName = action?.payload?.data?.itemGroupName;
          state.itemGroups.listingOrder = action?.payload?.data?.listingOrder;
        }
      }
    );
    builder.addMatcher(
      categoriesApi.endpoints.getitemdetailsbycategoryId.matchFulfilled,
      (state, action) => {
        const activeTabId = state?.activeTabId?.toString();
        state.addedListItemsToCategory = action.payload.data;
        if (state.activeStepper === '2') {
          state.addedListItemsToCategory = action.payload.data;
        } else if (state.activeStepper === '4') {
          if (state.addedListItemsToSubCategory[activeTabId]) {
            state.addedListItemsToSubCategory[activeTabId] =
              action.payload.data;
          } else {
            state.addedListItemsToSubCategory[0] = action.payload.data;
          }
        }
      }
    );
    builder.addMatcher(
      itemsApi.endpoints.getItemDetailsEdit.matchFulfilled,
      (state, action) => {
        state.packSizes = action.payload?.data
          ?.map((item: any) => {
            return item.itemPacks.map((pack: any, index: number) => ({
              ...pack,
              itemTypeCode: item.generalDetails?.itemTypeCode,
              id: `${item.generalDetails?.itemTypeCode}-Pack Size ${index + 1}`
            }));
          })
          .flat();
        state.packSizesData = action.payload?.data
          ?.map((item: any) => {
            return item.itemPacks.map((pack: any, index: number) => ({
              ...pack,
              itemTypeCode: item.generalDetails?.itemTypeCode,
              id: `${item.generalDetails?.itemTypeCode}-Pack Size ${index + 1}`
            }));
          })
          .flat();
        state.newItemsDataApiRes = {
          ...action.payload?.data[0],
          itemGeneralDetails: action.payload?.data?.map((item: any) => {
            return item.generalDetails;
          })
        };
        state.newItemsData = {
          ...action.payload?.data[0],
          itemGeneralDetails: action.payload?.data?.map((item: any) => {
            return item.generalDetails;
          })
        };
      }
    );
  }
});

export const {
  setNewItemsData,
  updateItemGeneralDetails,
  updateItemGeneralDetailsOnRequested,
  addNewItem,
  addPackSizes,
  updatePackSizes,
  removePackSize,
  setSearchValue,
  setIsAddButtonsClicked,
  setItemGroups,
  setAddListItemsToItemGroup,
  resetState,
  setAvtiveTabId,
  setActiveStepper,
  setSelectedRows,
  setItemDashboardDetails,
  setIsEditMode,
  setSelectedItemGroupId,
  setCheckedItemDetails,
  setSelectedItemIds,
  setSelectedRow,
  setAddListItemsToSubCategory,
  setIsSkuidAvailable,
  removeImages,
  setItemPacks
} = itemsSlice.actions;

export default itemsSlice.reducer;
