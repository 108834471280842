import api from './api';
import {
  CustomerItemdforOrderApiResponse,
  OrderIdDetailsByIDResponse,
  OrderItemPICKEDType,
  OrderItemType,
  OrdersCustomerSearch,
  RejectReasonResponse
} from './apiTypes';

export const ordersApi = api.injectEndpoints({
  endpoints: builder => ({
    flexiSearchCustomer: builder.query<OrdersCustomerSearch, string>({
      query: (searchValue: string) => ({
        url: `/api/web/v1/orders/flexisearchcustomer?flexitext=${searchValue}`,
        method: 'GET'
      })
    }),
    getOutletsOfCustomer: builder.query({
      query: (outletid: string) => ({
        url: '/api/web/v1/customer-outlet/getoutletdetails',
        method: 'GET',
        params: { outletid }
      })
    }),
    getOutletsStaff: builder.query({
      query: (outletid: string) => ({
        url: '/api/web/v1/customer-outlet/getstaffs',
        method: 'GET',
        params: { outletid }
      })
    }),
    createOrder: builder.mutation({
      query: (payload: any) => ({
        url: '/api/web/v1/orders/create',
        method: 'POST',
        body: payload
      })
    }),
    getItemsforCustomerOrder: builder.query<
      CustomerItemdforOrderApiResponse,
      number
    >({
      query: (customerid: any) => ({
        url: '/api/web/v1/customer/item/getitemsforcustomer-orders',
        method: 'GET',
        params: { customerid }
      })
    }),
    getOrderDetailsByID: builder.query<OrderIdDetailsByIDResponse, string>({
      query: (orderid: string) => ({
        url: `/api/web/v1/orders/orderdetailsbyid?orderid=${orderid}`,
        method: 'GET'
      })
    }),
    getRejectReasons: builder.query<RejectReasonResponse, void>({
      query: () => ({
        url: '/api/web/v1/orderreject/reason/getall',
        method: 'GET'
      })
    }),
    rejectOrder: builder.mutation({
      query: ({ orderId, reason }: { orderId: any; reason: any }) => ({
        url: '/api/web/v1/orders/rejectorder',
        method: 'PUT',
        body: reason,
        params: { orderId }
      })
    }),
    returnOrder: builder.mutation({
      query: ({
        orderId,
        OrderItems
      }: {
        orderId: any;
        OrderItems: OrderItemType[];
      }) => ({
        url: '/api/web/v1/orders/returnorder',
        method: 'PUT',
        body: OrderItems,
        params: { orderId }
      })
    }),
    pickedOrder: builder.mutation({
      query: ({
        orderId,
        OrderItems
      }: {
        orderId: any;
        OrderItems: OrderItemPICKEDType[];
      }) => ({
        url: '/api/web/v1/orders/fullfillmultipleorder',
        method: 'PUT',
        body: OrderItems,
        params: { orderId }
      })
    })
  })
});

export const {
  useFlexiSearchCustomerQuery,
  useLazyFlexiSearchCustomerQuery,
  useLazyGetOutletsOfCustomerQuery,
  useLazyGetOutletsStaffQuery,
  useCreateOrderMutation,
  useGetItemsforCustomerOrderQuery,
  useGetOrderDetailsByIDQuery,
  useLazyGetOrderDetailsByIDQuery,
  useGetRejectReasonsQuery,
  useRejectOrderMutation,
  useReturnOrderMutation,
  usePickedOrderMutation
} = ordersApi;
