import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { customersApi } from '../../../services/customersApi';
import { customersEditApi } from '../../../services/customersEditApi';
import { lookupsApi } from '../../../services/lookupsApi';

import {
  accountManagerTypes,
  creditCycleTypes,
  customerTypes,
  customersData,
  deliveryTimeSlotTypes
} from './customersInitialState';
import { CustomersInitialState } from './types';

const initialState: CustomersInitialState = {
  customers: customersData,
  customerTypes: [customerTypes],
  creditCycleTypes,
  customerDashboardDetails: { data: [], message: '', status: '' },
  customerItemDashboardDetails: { data: [], message: '', status: 'initial' },
  isAddCustomerButtonClicked: false,
  customerOutlets: {},
  customerStaff: {},
  cuisinetypes: [],
  designationTypes: [],
  allOutletsofCustomer: [],
  customerOutletStaff: [],
  customerItemGroups: { data: [], message: '', status: 'initial' },
  customerItemDetails: { data: {}, message: '', status: 'initial' },
  isCustomerDetailsAvailable: {
    isEmailAvailable: false,
    isPanAvailable: false,
    isMobileAvailable: false
  },
  customerDetailsEdit: {},
  customerOrderDetails: { data: [], message: '', status: '' },
  customerPaymentDetails: { data: {}, message: '', status: '' },
  nameClicked: false,
  data: customersData,
  staffData: {},
  brandName: '',
  accountManager: [accountManagerTypes],
  deliveryTimeSlot: [deliveryTimeSlotTypes]
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setNewCustomersData: (state, action) => {
      state.customers = {
        ...state.customers,
        ...action.payload
      };
    },
    setBrandName: (state, action) => {
      state.brandName = action.payload;
    },
    setNameClicked: (state, action) => {
      state.nameClicked = action.payload;
    },
    setIsAddCustomerButtonClicked: (state, action) => {
      state.isAddCustomerButtonClicked = action.payload;
    },
    addCustomerOutlets: (state, action) => {
      state.customerOutlets = action.payload;
    },
    addCustomerStaff: (state, action) => {
      state.customerStaff = action.payload;
    },
    setStaffData: (state, action) => {
      state.staffData = action.payload;
    },
    setCustomerOutlets: (state, action) => {
      const { key, value } = action.payload;
      return {
        ...state,
        customerOutlets: {
          ...state.customerOutlets,
          [key]: value
        }
      };
    },
    setCustomerStaff: (state, action) => {
      const { key, value } = action.payload;
      return {
        ...state,
        customerStaff: {
          ...state.customerStaff,
          [key]: value
        }
      };
    },
    setCustomerOutletStaff: (state, action) => {
      state.customerOutletStaff = action.payload;
    },
    resetIsCustomerDetailsAvailable: state => {
      state.isCustomerDetailsAvailable = {
        isEmailAvailable: false,
        isPanAvailable: false,
        isMobileAvailable: false
      };
    },
    resetCustomerState: state => {
      state.customers = customersData;
      state.data = customersData;
      state.isAddCustomerButtonClicked = false;
      state.customerStaff = {};
      state.customerOutletStaff = [];
      state.customerItemGroups = { data: [], message: '', status: 'initial' };
      state.customerOutlets = {};
      state.allOutletsofCustomer = [];
      state.isCustomerDetailsAvailable = {
        isEmailAvailable: false,
        isPanAvailable: false,
        isMobileAvailable: false
      };
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      customersApi.endpoints.getCustomerTypes.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerTypes = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.getCustomerDashboardDetails.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerDashboardDetails.data = action?.payload?.data;
      }
    );
    builder.addMatcher(
      customersEditApi.endpoints.editCustomerBasicDetails.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customers = action.payload.data;
        state.data = action.payload.data;
        state.customerDetailsEdit = {
          ...state.customerDetailsEdit,
          customerID: action.payload.data.id
        };
      }
    );
    builder.addMatcher(
      customersApi.endpoints.getCustomerItemDashboardDetails.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerItemDashboardDetails.data = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.getcuisinetypes.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.cuisinetypes = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.getCustomerOutlets.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.allOutletsofCustomer = action.payload.data;
      }
    );
    builder.addMatcher(
      lookupsApi.endpoints.getDesignationTypes.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.designationTypes = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.getCustomerItemGroups.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerItemGroups.data = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.getCustomerItemDetails.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerItemDetails.data = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.isCustomerEmailAvailable.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.isCustomerDetailsAvailable.isEmailAvailable = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.isCustomerPanAvailable.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.isCustomerDetailsAvailable.isPanAvailable = action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.isCustomerPhoneNumberAvailable.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.isCustomerDetailsAvailable.isMobileAvailable =
          action.payload.data;
      }
    );
    builder.addMatcher(
      customersApi.endpoints.fetchCustomerOrders.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerOrderDetails.data = action.payload.data;
      }
    );
    builder.addMatcher(
      customersEditApi.endpoints.getCustomerDetailsEdit.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerDetailsEdit = action.payload.data;
      }
    );
    builder.addMatcher(
      customersEditApi.endpoints.fetchCustomerPaymentDetails.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        const res = action.payload.data;
        state.customers.paymentDetails =
          action?.payload?.data?.postPaidDetails || {};
        state.data.paymentDetails =
          action?.payload?.data?.postPaidDetails || {};
      }
    );
    builder.addMatcher(
      lookupsApi.endpoints.getAccountManager.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.accountManager = action.payload.data;
      }
    );
    builder.addMatcher(
      lookupsApi.endpoints.getDeliveryTimeSlot.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.deliveryTimeSlot = action.payload.data;
      }
    );
  }
});

export const {
  setNewCustomersData,
  setIsAddCustomerButtonClicked,
  addCustomerOutlets,
  setCustomerOutlets,
  resetCustomerState,
  addCustomerStaff,
  setCustomerStaff,
  setCustomerOutletStaff,
  resetIsCustomerDetailsAvailable,
  setNameClicked,
  setStaffData,
  setBrandName
} = customersSlice.actions;

export default customersSlice.reducer;
