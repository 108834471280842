import { CategoryApiResponse } from '../../redux/Slices/CategoriesSlice/categoryTypes';
import api from '../api';
import {
  ApiResponse,
  CategoryDisplayData,
  GetMappedApiResponse
} from '../apiTypes';

export const categoriesApi = api.injectEndpoints({
  endpoints: builder => ({
    getCategoryDashboardDetails: builder.query<CategoryApiResponse, any>({
      query: ({ search }: any = {}) => {
        let url = '/api/web/v1/category/dashboarddetails';
        if (search) {
          url += `?searchtext=${search}`;
        }
        return {
          url,
          method: 'GET'
        };
      },
      providesTags: ['GetCategoryDashboardDetails']
    }),
    createCategory: builder.mutation({
      query: payload => ({
        url: '/api/web/v1/category/create',
        method: 'POST',
        body: payload
      })
    }),
    linkItemsTocategory: builder.mutation({
      query: (body: { categoryID: number; itemIDs: number[] }) => ({
        url: 'api/web/v1/category/linkitems',
        method: 'Put',
        body
      }),
      invalidatesTags: ['GetItemDetailsByGroup']
    }),
    unLinkItemCategory: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/category/delinkitems',
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['GetItemDetailsByCategoryId', 'GetItemDetailsByGroup']
    }),
    addSubCategories: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/category/addsub-categories',
        method: 'POST',
        body: payload
      })
    }),
    addSubCategoryApi: builder.mutation({
      query: ({ parentcategoryid, payload }: any) => ({
        url: `api/web/v1/category/addsub-category?parentcategoryid=${parentcategoryid}`,
        method: 'POST',
        body: payload
      })
    }),
    getItemsMappedToCategoryAndSubCategory: builder.query<
      GetMappedApiResponse,
      number | null
    >({
      query: (id: any) => ({
        url: `api/web/v1/item/getitemsmappedtocategoryandsub-categories?categoryId=${id}`,
        method: 'GET'
      })
    }),
    getitemdetailsbycategoryId: builder.query<ApiResponse, number | null>({
      query: categoryId => ({
        url: 'api/web/v1/item/getitemdetailsbycategoryId',
        method: 'GET',
        params: { categoryId }
      }),
      providesTags: ['GetItemDetailsByCategoryId']
    }),
    getCategoryDisplayData: builder.query<CategoryDisplayData, void>({
      query: () => ({
        url: '/api/web/v1/category/categorydisplay',
        method: 'GET'
      })
    }),
    getSubCategoriesByCategoryId: builder.mutation({
      query: payload => ({
        url: 'api/web/v1/category/getsub-categories',
        method: 'POST',
        body: payload
      })
    }),
    inActivateSubCategory: builder.mutation({
      query: subcategoryid => ({
        url: 'api/web/v1/category/inactivate-subcategory',
        method: 'PUT',
        params: { subcategoryid }
      }),
      invalidatesTags: ['GetSubCategoryDashboard']
    }),
    switchStatus: builder.mutation({
      query: ({ status, subcategoryid }) => ({
        url: 'api/web/v1/category/switch-status',
        method: 'PUT',
        params: { status, subcategoryid }
      }),
      invalidatesTags: ['GetSubCategoryDashboard']
    })
  })
});

export const {
  useGetCategoryDashboardDetailsQuery,
  useLazyGetCategoryDashboardDetailsQuery,
  useCreateCategoryMutation,
  useLinkItemsTocategoryMutation,
  useUnLinkItemCategoryMutation,
  useAddSubCategoriesMutation,
  useGetItemsMappedToCategoryAndSubCategoryQuery,
  useLazyGetItemsMappedToCategoryAndSubCategoryQuery,
  useGetitemdetailsbycategoryIdQuery,
  useLazyGetitemdetailsbycategoryIdQuery,
  useAddSubCategoryApiMutation,
  useGetCategoryDisplayDataQuery,
  useGetSubCategoriesByCategoryIdMutation,
  useInActivateSubCategoryMutation,
  useSwitchStatusMutation
} = categoriesApi;
