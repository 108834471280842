import React, { ErrorInfo, ReactNode } from 'react';

import SomethingWentWrongPage from '../pages/SomethingWentWrongPage';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrongPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

function logErrorToMyService(error: Error, errorInfo: ErrorInfo) {
  // Your error logging logic here
}
